<template>
  <v-container>
    <v-card flat>
      <v-card-title class="headline">
        Media Library
      </v-card-title>
      <v-card-text>
        <asset-gallery
          :path="path"
          router
          @path-change="updatePath"
        />
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
export default {
  name: 'Media',
  computed: {
    path() {
      return this.$route.fullPath.substring('/media'.length)
    }
  },
  methods: {
    updatePath(event) {
      const path = event.split('/').filter(x => x !== '').join('/')
      this.$router.push(path ? `/media/${path}` : '/media')
    }
  }
}
</script>
